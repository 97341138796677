<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/law_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">为您提供优秀的咨询与服务</div>
				<div class="desc fontsize18 colorwhite">知识产权法律咨询服务平台</div>
			</div>
		</div>
		
		<div class="block20"></div>
		<!-- 栏目菜单 -->
		<div class="huaxue_home_type w1239">
			<div class="title fontsize38">产权法律</div>
			<div class="typelist" v-if="typeList.length>0">
				<div class="typeobj fontsize22" :class="{'hover':cur_index==index}" v-for="(item,index) in typeList" :key="index" @click="handleType(index)">{{item.name}}</div>
			</div>
		</div>
		<div class="law_datalist_box w1260">
			<div class="data_item pointer" v-for="(item,index) in dataList" :key="index" @click="clickarticleDetail(item.id)">
				<div class="img"><img :src="item.imgUrl || require('../assets/static/logo.png')"></div>
				<div class="data_con">
					<div class="title fontsize16">{{item.title}}</div>
					<div class="desc fontsize14 clamp3">{{item.body}}</div>
					<div class="timer fontsize14">时间：{{item.releaseTime || item.timer}}</div>
				</div>
			</div>
			<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
			<div class="block30"></div>
			<div class="w1239 phonenone" style="text-align:right">
			    <el-pagination
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next, jumper"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="w1239 pcnone" style="text-align:right">
			    <el-pagination
				      small
			          @current-change="handleCurrentChange"
			          :current-page.sync="currentPage"
			          :page-size="pageSize"
			          layout="prev, pager, next"
			          :total="total">
			    </el-pagination>
			</div>
			<div class="block20"></div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			typeList:[],
			cur_index:0,//当前下标
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(11).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		this.getZhishichanquanfalvType()
		if(this.$route.query&&this.$route.query.type){
			this.cur_index = parseInt(this.$route.query.type)
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
			this.getzhishichanquanfalv()
		},
		//点击类型
		handleType(index){
			if(this.cur_index!=index){
				this.cur_index = index
				this.getzhishichanquanfalv()
			}
		},
		//获取(文章)获得知识产权法律
		getzhishichanquanfalv() {
			var _this = this;
			var params = {
				currentPage: this.currentPage,
				pageSize: this.pageSize,
			}
			if(_this.typeList.length>0){
				params["realTimeTypeId"] = _this.typeList[_this.cur_index].id
			}else{
				params["realTimeTypeId"] = null
			}
			this.$http.post('frontEnd/law/getzhishichanquanfalv', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item,index)=>{
						if(item.contents){
							item["body"] = _this.$util.filtersText(item.contents,318)
							item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
						}
						if(item.releaseTime){
							item["releaseTime"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
						}
					})
					_this.total = res.data.total
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取(文章类型)知识产权法律
		getZhishichanquanfalvType() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
			};
			this.$http.post('frontEnd/law/getZhishichanquanfalvType', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.typeList = records
					}
					//列表
					_this.getzhishichanquanfalv()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
